import React ,{useState,useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { SyncBySupplier,checkedSupplier } from "../../../store/productsSlice";
import {  PushBySupplierToCheckFound_} from "../../../store/NotInOrdersSlice";
import "../../../assets/css/PointCashier/FilterBySuppliers/FilterBySuppliers.css";
import config from "../../../lib/config";

function FilterBySuppliers(props) {
  const { products ,BySupplier} = useSelector((state) => state.product);
  const [audio] = useState(new Audio(config.get("audio.sound_success")));
  const [supplierSelect, setSupplierSelect] = useState(null);
  const dispatch = useDispatch();

  const Pip= (e) =>  {
    audio.pause();
    audio.play().onended = function () {
      this.currentSrc = null;
      this.src = "";
      this.srcObject = null;
      this.remove();
    };
  }
  
    useEffect(() => {
      if (products.length >= 1) {
        dispatch(SyncBySupplier());
      }
    }, [products, dispatch]);
  
  const supplier =
  BySupplier.length >= 1 ? (
    BySupplier.map((Item, index) => (
      <div key={index} className="Supplier">
     <div className="op-btn">
 
      <div className="pushAction sh Transition" onClick={() => handelSendToCheck_is_Found(Item)}>
        <i className="fas fa-truck-loading"></i>
      </div>
      </div>

      <div className="details">
        <strong className="title">المورد / {Item.supplier_name} </strong>
        <div className="info"> <span>متوفر  <strong>{Item.count_item}</strong>  صنف   </span>
        </div>
      </div>
      <div onClick={() => handleSelect(  Item.supplier_id)} className={supplierSelect===Item.supplier_id ? "checkAction Transition Checked-true" : "checkAction Transition"}>
        <i className="fa fa-check"></i>
      </div>
    </div>
    ))
  ) : (<div className="Notfound" style={{ height: "250px", backgroundSize: "75%" }}></div>);

  const handleSelect = (supplierId) => {
    if (supplierId === supplierSelect) {
      setSupplierSelect(null);
      dispatch(checkedSupplier(null));
    }else {
      setSupplierSelect(supplierId);
    dispatch(checkedSupplier(supplierId));
    };
  }
 
const handelSendToCheck_is_Found = (Item) => {
  dispatch(PushBySupplierToCheckFound_(Item));
  Pip();
}

 
  return (
    <div className="filterContainer">

      <div className="style16">
       <div className="icon"></div>
            <div className="title">
              <h2> إدارة منتجات الموردين ...</h2>
            </div>
            <div className="btnBar">
              <div className="Button-custom Transition">
                <i className="fas  fa-ad" aria-hidden="true"></i>
              </div>
              <div className="Button-custom Transition">
                <i className="fas  fa-folder-open" aria-hidden="true"></i>
              </div>

              </div>

      </div>
 
        <div className="SuppliersList" id="transparent-scroll">
          {supplier}
        </div>
        
 

    </div>
  );
}
export default FilterBySuppliers;
