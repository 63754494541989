import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
export const SyncPagesList = createAsyncThunk(
  "Telegram/SyncPagesList",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const PagesList = endpoint
        .post("page/sync_my_pages_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return PagesList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SaveBot_ = createAsyncThunk(
  "Telegram/SaveBot_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Marketers = endpoint
        .post("telegram/save_bot_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          Bot: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Marketers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const SyncBotList = createAsyncThunk(
  "Telegram/SyncBotList",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const BotList = endpoint
        .post("telegram/default", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return BotList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteBot_ = createAsyncThunk(
  "Telegram/DeleteBot_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Bot_ = endpoint
        .post("telegram/remove_bot_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          Bot: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Bot_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ActiveBot_ = createAsyncThunk(
  "Telegram/ActiveBot_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Bot_ = endpoint
        .post("telegram/active_bot_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          Bot: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Bot_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncTelegramGroups = createAsyncThunk(
  "Telegram/SyncTelegramGroups",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const GroupList_ = endpoint
        .post(
          "telegram/_sync_groups_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            Type: "supergroup",
            LIMIT: 0,
          })
        )

        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return GroupList_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SyncTelegramChanel = createAsyncThunk(
  "Telegram/SyncTelegramChanel",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const GroupList_ = endpoint
        .post(
          "telegram/_sync_chanel_",
          JSON.stringify({ UserId: parseInt(getState().auth.UserId), LIMIT: 0 })
        )

        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          // console.log(`---Error ---Categories > ${error} ----`);
        });
      return GroupList_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const Sync_pushToGroup_ = createAsyncThunk(
  "Telegram/Sync_pushToGroup_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post(
          "/bot/push_to_group_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            Item: getState().NotInOrders.ids,
            group: _,
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const Sync_pushTo_ = createAsyncThunk(
  "Telegram/Sync_pushTo_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Result_update_order = endpoint
        .post(
          "/bot/push_to_audion_",
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            Type: _[1],
            Item: getState().catalog.ItemIds,
            group: _[0],
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Result_update_order;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const SaveEditChanelGroup_ = createAsyncThunk(
  "Telegram/SaveEditChanelGroup_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Marketers = endpoint
        .post("telegram/save_edit_chg_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          cg_: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Marketers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteChGroup_ = createAsyncThunk(
  "Telegram/DeleteBot_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Bot_ = endpoint
        .post("telegram/remove_ch_group_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          chg: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Bot_;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

//SetActiveAudience

//============---------------===========--------==========----------=========-
// telegram/active_bot_

export const FindByClintName = createAsyncThunk(
  "clint/FindByClintName",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const Categories = endpoint
        .post("clients/find_by_name_", {
          userId: parseInt(getState().auth.UserInfo["UserId"]),
          ClintName: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      return Categories;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  isLoadingChanel: false,
  isLoading: false,
  BotList: [],
  CloneBotList: [],
  SelectedClint: [],
  PagesList: [],
  GroupsList: [],
  ChanelList: [],

  showList: false,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
  isEdit: false,
  isEditChanel: false,
  selectedEdit: [],
  ItemIds: [],
};
const TelegramSlice = createSlice({
  name: "Telegram",
  initialState: initialState,
  reducers: {
    ONUpdate: (state) => {
      state.isEdit = false;
      state.isEditChanel = false;
      state.SelectedClint = [];
      state.ErrorHandling = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
    },

    SelectToEditBot: (state, action) => {
      state.isEdit = true;
      state.selectedEdit = action.payload;
    },
    SelectToEditChanel: (state, action) => {
      state.isEditChanel = true;
      state.selectedEdit = action.payload;
    },
  },
  extraReducers: {
    [SyncTelegramChanel.pending]: (state, action) => {
      // state.isLoadingChanel = true;
      state.error = null;
    },
    [SyncTelegramChanel.fulfilled]: (state, action) => {
      // state.isLoadingChanel = false;
      state.error = null;
      state.ChanelList = action.payload;
    },
    [SyncTelegramChanel.rejected]: (state, action) => {
      state.isLoadingChanel = false;
      state.error = action.payload;
    },

    [Sync_pushTo_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [Sync_pushTo_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
    },
    [Sync_pushTo_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [Sync_pushToGroup_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [Sync_pushToGroup_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
    },
    [Sync_pushToGroup_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncTelegramGroups.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncTelegramGroups.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.GroupsList = action.payload;
    },
    [SyncTelegramGroups.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [ActiveBot_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [ActiveBot_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
      // console.log(action.payload);
    },
    [ActiveBot_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [FindByClintName.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [FindByClintName.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.BotList = action.payload;
    },
    [FindByClintName.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SaveEditChanelGroup_.pending]: (state, action) => {
      state.isLoadingChanel = true;
      state.error = null;
    },
    [SaveEditChanelGroup_.fulfilled]: (state, action) => {
      state.isLoadingChanel = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [SaveEditChanelGroup_.rejected]: (state, action) => {
      state.isLoadingChanel = false;
      state.error = action.payload;
    },

    //DeleteChGroup_

    [DeleteChGroup_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [DeleteChGroup_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [DeleteChGroup_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [SaveBot_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SaveBot_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [SaveBot_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [DeleteBot_.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [DeleteBot_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.error) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.error.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
      }
    },
    [DeleteBot_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SyncPagesList.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncPagesList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.PagesList = action.payload;
    },
    [SyncPagesList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    [SyncBotList.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [SyncBotList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.BotList = action.payload;
      state.CloneBotList = action.payload;
    },
    [SyncBotList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // [CreateNewOrder_.fulfilled]: (state, action) => {
    //   console.log(action.payload);
    // }
  },
});
export const { SelectToEditBot, SelectToEditChanel, findClint, ONUpdate } =
  TelegramSlice.actions;
export default TelegramSlice.reducer;
