import React from "react";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";

const ItemOrder = (props) => {
  let {
    OrderList,
    isLoading,
    onChange,
    dispatch,
    HideList,
    Remove,
    IsSelectOrder,
    ClearCart,
    switchToEdit,
    ShowInvoice,
  } = props;

  const Removed = (Item) => {
    Swal.fire({
      title: "تحذير هام ?",
      text: "هل انت متأكد من حذف الاوردر !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "إلغاء",
      confirmButtonText: "نعم حذف !",
    }).then((result) => {
      if (result.isConfirmed) {
        if (Item.Invoice_ID === IsSelectOrder.Invoice_ID) {
          dispatch(ClearCart());
        }
        dispatch(Remove(Item));
        Swal.fire("Deleted!", "تم حذف الاوردر نهئياً.", "success");
      } else {
        Swal.fire("تم الغاء حذف الاوردر", "", "info");
      }
    });
  };

  const detectItemCheckStatus = (item, index) => {
    dispatch(onChange([item, index]));
    setTimeout(() => {
      dispatch(HideList(false));
      dispatch(switchToEdit(false));
      dispatch(ShowInvoice());
    }, 250);
  };

  const List =
    OrderList.length >= 1 ? (
      OrderList.map((Item, index) => (
        <div
          key={index}
          className={`Order Transition ${Item.checked ? "Selected" : ""}`}
        >
          <div className="info">
            <div className="userImg TRnsion"></div>
            <div
              className="Clint-container"
              onClick={() => detectItemCheckStatus(Item, index)}
            >
              <div className="name"> {Item.Client_Name}</div>

              <div className="orderPay" style={{ direction: "ltr" }}>
                <span className="totalOrder">
                  ج.م <strong>{Item.Total_Invoice}</strong>
                </span>
                -
                <span className="total_pcs">
                  منتج <strong> {Item.Pcs} </strong>
                </span>
              </div>
            </div>
          </div>
          {Item.Buy_Deposit >= 1 && (
            <div className="Notfi NF" title="  ج ضمان ">
              <i className="fas fa-dollar-sign"></i>
            </div>
          )}
          <div className="ClearOrder" onClick={() => Removed(Item)}>
            <i className="fa fa-trash "></i>
          </div>
        </div>
      ))
    ) : (
      <div className="Notfound" style={{ height: "300px!important;" }}></div>
    );

  return (
    <>
      {isLoading ? (
        <div className="loading">
          <CircularProgress disableShrink />
        </div>
      ) : (
        <>{List}</>
      )}
    </>
  );
};

export default ItemOrder;
