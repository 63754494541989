import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SyncTelegramChanel } from "../../../store/TelegramSlice";
import {
  NewCamp_,
  Sync_Campaign,
  ONUpdate,
  SelectCh,
  changePriceType,
  checkedOBject,
  RemoveChanelObjective,
  SyncObjectComping,
} from "../../../store/PublishCampaignSlice";
import "../../../assets/css/PointCashier/PublishCampaign/PublishCampaign.css";
import { SwToast } from "../../../lib/SwAlert";

function FormCampaign() {
  const { ChanelList } = useSelector((state) => state.Telegram);
  const {
    ItemObj,
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    EditCamp,
    isEdit,
  } = useSelector((state) => state.publish_campaign);
  const [chanel, setChanel] = useState([]);
  const [showPrice, setShowPrice] = useState(0);
  const [Block, setBlock] = useState(0);
  const [SortByPrice, setSortByPrice] = useState(0);
  const [SortByAction, setSortByAction] = useState(0);
  const [autoFill, setAutoFill] = useState(false);

  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(SyncTelegramChanel()), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () =>
      setChanel({
        ...ChanelList[0],
        Block_Type: Block,
        show_Price: showPrice,
        checked: false,
      }),
    [ChanelList]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () =>
      setChanel({
        ...chanel,
        Block_Type: Block,
        show_Price: showPrice,
        checked: false,
      }),
    [Block, showPrice]
  );

  const vL = Yup.string()
    .required("The value is required !")
    .min(4, "Must be 5 characters ")
    .max(100, "Must be 100 characters or less")
    .required("Required");
  const validationSchemaNewProduct = Yup.object({
    Campaign_title: vL,
    Active: Yup.number()
      .typeError("The value Number ")
      .integer("The value must Number "),
  });

  const formik = useFormik({
    initialValues: { id: 0, Campaign_title: "", Active: 1 },
    validationSchema: validationSchemaNewProduct,
    onSubmit: (values, { resetForm }) => {
      handelSave(JSON.stringify(values, null, 2), resetForm);
    },
  });

  useEffect(() => {
    if (isEdit) {
      formik.setFieldValue("id", EditCamp.Id);
      formik.setFieldValue("Campaign_title", EditCamp.Title);
      formik.setFieldValue("Active", EditCamp.Active);
      dispatch(SyncObjectComping(EditCamp.Id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, EditCamp]);

  const FMkv = formik.values;
  const FMKEr = formik.errors;
  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.Campaign_title) SwToast("warning", `اضف عنوان للحملة`);
      // console.log(FMKEr);
    }, 300);
    return () => {
      clearTimeout(st_);
    };
  }, [FMKEr]);

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` 💚 ${HandlingSuccessMsg}`);
      dispatch(ONUpdate());
      formik.resetForm({ values: "" });
    }
    if (ErrorHandling === true) {
      SwToast("info", `🤔 ${ErrorHandlingMsg}`);
      dispatch(ONUpdate());
    }
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    formik,
    dispatch,
  ]);

  const handelSave = (values, reset) => {
    //modifiedData remove some values
    const modifiedData = {
      ...ItemObj.map((item) => {
        const {
          botName,
          username,
          first_name,
          last_name,
          type,
          active,
          checked,
          ...rest
        } = item;
        return rest;
      }),
    };
    const Item = modifiedData;
    if (ItemObj.length >= 1) {
      dispatch(NewCamp_({ values, Item ,autoFill, SortByPrice, SortByAction}));
      dispatch(Sync_Campaign());
    } else {
      SwToast("info", `🤔 حدد اهداف حملة النشر  `);
    }
  };

  // Handle the change event when the user selects a new option
  const handleSelectChange = (event) => {
    // eslint-disable-next-line
    const findCh = ChanelList.find((item) => item.Au_Id == event.target.value);
    if (findCh) {
      setChanel({
        ...findCh,
        Block_Type: Block,
        show_Price: showPrice,
        checked: false,
      });
    }
  };

  const handelAddChanel = () => {
    if (chanel.Au_Id) {
      dispatch(SelectCh(chanel));
    }
  };

  const handleChangeShowPrice = (item) => {
    dispatch(changePriceType(item));
  };

  const handelPrice = (event) => {
    setShowPrice(event.target.value);
  };

  const handelBlock = (event) => {
    setBlock(event.target.value);
  };
  const handelByPrice = (event) => {
    setSortByPrice(event.target.value);
  };
  const handelByAction = (event) => {
    setSortByAction(event.target.value);
  };
  const handleAutoFill = (event) => {
    setAutoFill(event.target.checked);
  };

  const Ch_List =
    ChanelList.length >= 1 &&
    ChanelList.map((Item, index) => (
      <option key={index} value={Item.Au_Id}>
        {Item.first_name}
      </option>
    ));
  const ItemList =
    ItemObj.length >= 1 &&
    ItemObj.map((Item, index) => (
      <div className="item-obj" key={index} value={Item.Au_Id}>
        <div
          className={`check Transition ${Item.checked ? "checked" : ""}`}
          onClick={() => dispatch(checkedOBject(Item))}
        ></div>
        <div className="title"> {Item.first_name} </div>
        <div className="op">
          <span>
            {" "}
            {
              // eslint-disable-next-line
              Item.Block_Type == 1 ? "قالب كامل" : "غلاف"
            }{" "}
          </span>

          <div
            onClick={() => handleChangeShowPrice(Item)}
            className={`showPrice ${
              Item.show_Price >= 1 ? "active" : "UnActive"
            }`}
          ></div>
        </div>
      </div>
    ));

  return (
    <>
      <div className="EditCampaign">
        <form onSubmit={formik.handleSubmit}>
          <div className="style16">
            <div className="icon"></div>
            <div className="title">
              <h2> إدارة الحملات الاعلانية</h2>
            </div>
            <div className="btnBar">
              <div className="Button-custom Transition">
                <i className="fas  fa-ad" aria-hidden="true"></i>
              </div>
              <div className="Button-custom Transition">
                <i className="fas  fa-folder-open" aria-hidden="true"></i>
              </div>
              <div
                className="Button-custom Transition"
                onClick={() => dispatch(Sync_Campaign())}
              >
                <i className="fas  fa-sync" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div className="pointCamp">
            <input
              name="Campaign_title"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.Campaign_title}
              autoComplete={"off"}
              className={`title ${
                FMKEr.Campaign_title ? "inputError" : "inputTrue"
              }`}
              placeholder="عنوان الحملة   .... "
            />

            <select
              name="Active"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.Active}
              className={`list`}
            >
              <option value={1}> نشـطة </option>
              <option value={0}> غير نشطة</option>
            </select>
            <button type="submit" className="btn-success">
              حفظ
            </button>
          </div>
        </form>
        <div className="OBjCmp">
          <div className="title">اهداف الحملة الاعلانية </div>
          <i className="fas fa-folder-minus"></i>
        </div>

        <div className="listOBJ">
          <div className="formObject">
          

          <div className="opComp">
              <select
              name="show_Price"
              type="text"
              className={`list`}
              value={chanel.Au_Id}
              onChange={handleSelectChange}
            >
              {Ch_List}
            </select>

            <select
              name="show_Price"
              type="text"
              className={`list`}
              value={Block}
              onChange={handelBlock}
            >
              <option value={0}> غـلاف</option>
              <option value={1}> قالب كامل </option>
            </select>
            <select
              name="show_Price"
              type="text"
              value={showPrice}
              className={`list`}
              onChange={handelPrice}
            >
              <option value={0}> بدون الاسعار</option>
              <option value={1}> بالاسعار </option>
            </select>
            <div className="btnAdd" onClick={() => handelAddChanel()}>
              <i className="fas fa-plus"></i>
            </div>
            <div
              className="btnAdd"
              onClick={() => dispatch(RemoveChanelObjective())}
            >
              <i className="fas fa-trash"></i>
            </div>
          </div>
          <div className="opComp">  
          <label>
        <input    type="checkbox" name="AutoFill"  checked={autoFill}  onChange={handleAutoFill}  className=""/>  تعبئة تلقائية! </label>            
          <select
              name="SortByPrice"
              type="text"
              className={`list`}
              value={SortByPrice}
              onChange={handelByPrice}
            >
              <option value={0}> الاقل سعر</option>
              <option value={1}>الاعلى سعر  </option>
            </select>
            
            <select
              name="sortByAction"
              type="text"
              className={`list`}
              value={SortByAction}
              onChange={handelByAction}
            >
              <option value={0}> التصنيف الاقل</option>
              <option value={1}> التصنيف الاعلى </option>
            </select>
            
             </div>
          </div>
          {ItemObj.length >= 1 && (
            <>
              <div className="HeadComp">
                <div className="TitleComp"> الهــدف </div>
                <div className="op">
                  <span> القالب </span> <span> السعر </span>
                </div>
              </div>
              {ItemList}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FormCampaign;
