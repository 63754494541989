import config from "../lib/config";
//

export default config.set({
  endpoint: {
    baseUrl: "https://api.ybe3.com",
    apiKey: "d5719d30969712c0053203524a1e66b4218871bdf975d406e8b9a9a7ca24352e",
  },
  URLs: {
    HeaderCoverIMG: "/uploads/User/Thumbnails/",
    product_Img: "/uploads/products/",
    product_ImgThumbnails: "/uploads/products/",
    Cover_Thumbnails: "/uploads/Cover/Thumbnails/",
    product_ImgSmThumbnails:      "/uploads/products/SmThumbnails/",
    UserImg: "/uploads/images/Users/smThumbnails/",
    PImage: "/uploads/uiPage/Thumbnails/",
    CatCover: "/uploads/CatCover/",
    CatThumbnails: "/uploads/CatCover/Thumbnails/",
    CustomThumbnails: "/uploads/CatCover/Thumbnails/",
  },
   audio: {
     sound1: "/images/sound/Notficaton.mp3",
     sound_success: "/images/sound/Alert.mp3",
     sound_Boom: "/images/sound/boom.mp3",
     coins: "/images/sound/coins-spill.mp3",
   },
});


/* export default config.set({
  endpoint: {
    baseUrl: "https://api.ybe3.com",
    apiKey: "d5719d30969712c0053203524a1e66b4218871bdf975d406e8b9a9a7ca24352e",
  },
  URLs: {
    HeaderCoverIMG: "https://www.ybe3.com/uploads/User/Thumbnails/",
    product_Img: "https://www.ybe3.com/uploads/products/",
    product_ImgThumbnails: "https://www.ybe3.com/uploads/products/",
    Cover_Thumbnails: "https://www.ybe3.com/uploads/Cover/Thumbnails/",
    product_ImgSmThumbnails:
      "https://www.ybe3.com/uploads/products/SmThumbnails/",
    UserImg: "https://www.ybe3.com/uploads/images/Users/smThumbnails/",
    PImage: "https://www.ybe3.com/uploads/uiPage/Thumbnails/",
    CatCover: "https://www.ybe3.com/uploads/CatCover/",
    CatThumbnails: "https://www.ybe3.com/uploads/CatCover/Thumbnails/",
    CustomThumbnails: "https://www.ybe3.com/uploads/CatCover/Thumbnails/",
  },
  audio: {
    sound1: "/images/sound/Notficaton.mp3",
    sound_success: "/images/sound/Alert.mp3",
    sound_Boom: "/images/sound/boom.mp3",
    coins: "/images/sound/coins-spill.mp3",
  },
}); */

//local configurations
/* export default config.set({
  endpoint: {
    baseUrl: "http://www.api.me/",
    apiKey: "399adaf519426e227208679e5fd475befdb9491d62b9a4fea1128a943005edfd",
  },
  URLs: {
    userIMG: "http://www.apimg.me/uploads/User/Thumbnails/",
    product_Img: "http://www.apimg.me/uploads/products/",
    product_ImgThumbnails: "http://www.apimg.me/uploads/products/",
    product_ImgSmThumbnails:"http://www.apimg.me/uploads/products/SmThumbnails/",
    Cover_Thumbnails: "http://www.apimg.me/uploads/Cover/Thumbnails/",
    Cover_smThumbnails: "http://www.apimg.me/uploads/Cover/SmThumbnails/",
    UserImg: "http://www.apimg.me/uploads/User/Thumbnails/",
    uiPage: "http://www.apimg.me/uploads/uiPage/Thumbnails/",
    CatCover: "http://www.apimg.me/uploads/CatCover/",
    CatThumbnails: "http://www.apimg.me/uploads/CatCover/Thumbnails/",
    CustomThumbnails: "http://www.apimg.me/uploads/CatCover/Thumbnails/",
  },
  audio: {
    sound1: "/images/sound/Notficaton.mp3",
    sound_Boom: "/images/sound/boom.mp3",
    sound_success: "/images/sound/Alert.mp3",
    coins: "/images/sound/coins-spill.mp3",
  },
});
 */
