import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SyncOrders_,
  findOrders,
  selectOrder,
  switchList,
  deleteOrder,
} from "../../../store/OrderSlice";
import { switchToEdit, ShowInvoice } from "../../../store/productsSlice";
import { ClearCart } from "../../../store/cartSlice";
import { switchClintList } from "../../../store/clintSlice";
import ItemOrder from "./ItemOrder";
import "../../../assets/css/PointCashier/Orders/Orders.css";
import { SwToast } from "../../../lib/SwAlert";

function Orders(props) {
  // const { scrollPosition } = props;
  const { OrderList, isLoading, IsCreateOrder, showList, IsSelectOrder } =
    useSelector((state) => state.Orders);
  const { EditProduct } = useSelector((state) => state.product);

  const [NamePhone, setNamePhone] = useState("");
  const dispatch = useDispatch();

  function isInt(n) {
    return n % 1 === 0;
  }

  useEffect(() => {
    dispatch(SyncOrders_());
  }, [dispatch]);

  useEffect(() => {
    if (IsCreateOrder) {
      SwToast("success", "successfully Create order ");
      dispatch(SyncOrders_());
    } else if (IsCreateOrder === false) {
      SwToast("error", "Order is already Open ! ");
    }
  }, [IsCreateOrder, dispatch]);

  useEffect(() => {
    if (NamePhone) {
      const st_ = setTimeout(() => {
        dispatch(
          findOrders([NamePhone, isInt(NamePhone) ? "Phone" : "ByName"])
        );
      }, 600);
      return () => {
        clearTimeout(st_);
      };
    } else {
      dispatch(findOrders([NamePhone, isInt(NamePhone) ? "Phone" : "ByName"]));
    }
  }, [NamePhone, dispatch]);

  useEffect(() => {
    if (showList) {
      dispatch(switchClintList(false));
    }
  }, [dispatch, showList]);

  return (
    <>
      <div>
        <div
          className="AtoCalc Transition BTN_"
          onClick={() => dispatch(switchList(!showList))}
        >
          <i className="fa fa-cart-arrow-down "></i>
        </div>
        {showList ? (
          <div
            id="Content_Orders"
            className="Transition"
            // style={{ top: scrollPosition >= 15 ? "65px" : "115px" }}
          >
            <div className="nav_order">
              <h2>
                {" "}
                <i className="fa fa-shopping-cart"></i> قائمة الاوردرات{" "}
              </h2>
              <div
                className="SyncOrderList"
                onClick={() => dispatch(SyncOrders_())}
              >
                <i className="fas fa-sync-alt"></i>
              </div>
              <div className="Searches">
                <input
                  type="text"
                  onChange={(e) => setNamePhone(e.target.value)}
                  placeholder="  Name ,Phone   بحث عن اوردر "
                  value={NamePhone}
                  className="ClientSearches"
                />
              </div>
            </div>
            <div className="OrderList" id="transparent-scroll">
              <Fragment>
                <ItemOrder
                  OrderList={OrderList}
                  IsSelectOrder={IsSelectOrder}
                  ClearCart={ClearCart}
                  switchToEdit={switchToEdit}
                  EditProduct={EditProduct}
                  dispatch={dispatch}
                  Remove={deleteOrder}
                  isLoading={isLoading}
                  onChange={selectOrder}
                  HideList={switchList}
                  ShowInvoice={ShowInvoice}
                />
              </Fragment>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Orders;
